<template>
  <div class="col-12 c g">
    <div class="card card-body">
        <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm">
                <thead>
                    <th>
                        النوع
                    </th>
                    <th>
                        التاريخ
                    </th>
                    <th>
                        الحالة
                    </th>
                    <th>
                        الرد
                    </th>
                </thead>
                <tbody>
                    <tr v-for="h in mails" :key="h._id">
                        <td>
                            {{ h.type.replace("ghiab", "غياب").replace("late", "تأخر").replace("other", types[h.type]) }}
                        </td>
                        <td>
                            {{ h.date }}
                        </td>
                        <td>
                            <span class="text-danger" v-if="h.teacher_reply == null">لم يتم الرد</span>
                            <span class="text-success" v-if="h.teacher_reply != null">تم الرد</span>
                        </td>
                        <td>
                            <a :href="`https://s.tahdir.net/teacher-ask/${h._id}`" target="_blank" class="btn btn-sm btn-primary" v-if="h.teacher_reply == null">
                                <i class="fa fa-external-link"></i>
                                رد الآن
                            </a>
                            <span v-if="h.teacher_reply != null">
                                <a :href="`https://s.tahdir.net/teacher-ask/${h._id}`" target="_blank" class="btn btn-sm btn-primary">
                                    <i class="fa fa-external-link"></i>
                                    عرض المسائلة
                                </a>
                                {{ h.teacher_reply }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BFormCheckbox,
    },
    directives: {
        Ripple,
    },
    data(){
        return {
            teacher: JSON.parse(localStorage.getItem('teacher')),
            mails: [],
            types: [
                "عدم تواجد داخل الفصل",
                "خروجك من الحصة قبل انتهاء الوقت",
                "عدم تحضير الدروس",
                "عدم المناوبة في الفسحة",
                "عدم المناوبة في الممرات",
                "عدم المناوبة في نهاية الدوام",
                "عدم الحضور لحصص الانتظار"
            ],
        }
    },
    created(){
        var g = this;
        $.post(api + '/teacher/asks', {
            username: this.teacher.username,
            password: this.teacher.password
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.mails = r.response;
            }
        }).fail(function(){
        })
    },
    methods: {
    }
}
</script>

<style>
.classroom{
    padding: 15px; 
    border: 2px solid #ddd;
    border-radius: 5px;
}
.classroom:hover{
    background: #fafafa !important;
    cursor: pointer;
    border: 2px solid #333;
}
</style>